import React from 'react'
import { IframeVimeo } from './components/VideoIframe/Iframe'
import { Pagination } from './components/Pagination'
import { MapProps } from './types'

export const HelpHome = () => (
  <Pagination>
    <div>
      <h3>Bem vindo(a) ao Vista!</h3>

      <p>Nesta tela você verá algumas informações resumidas sobre a sua saúde financeira.</p>

      <p>
        No gráfico abaixo você poderá comparar os seus investimentos, receitas, despesas e dívidas que foram
        realizados ao longo do prazo escolhido.
      </p>

      <p>Por padrão este prazo será o mês atual.</p>

      <IframeVimeo title="Home" videoId="1006569242?h=5596fd4776" />
    </div>

    <div>
      <IframeVimeo title="Vista AI" videoId="1006570863?h=74493a4118" />
    </div>

    <div>
      <IframeVimeo title="Bloco de Notas" videoId="1008221505?h=c63681e481" />
    </div>
  </Pagination>
)

export const HelpPlanosSonhos = () => (
  <div>
    <IframeVimeo title="Planos e Sonhos (Ajuda)" videoId="1005955264?h=e38ba4387c" />
  </div>
)

export const HelpGestaoPlanos = () => (
  <Pagination>
    <div>
      {/* <p>
      Nesta tela você conseguirá visualizar os dados de todos os planos, bem como o valor total necessário que
      você deve investir mensalmente para alcançar os seus objetivos.
    </p>
    <p>
      Na gestão de sonhos você pode alterar os campos como taxa, prazo, valor presente, inflação e parcela.
      Faça o teste mudando os parâmetros e ajustando mais próximo da sua realidade.
    </p>
    <p>
      Tente chegar em um valor de parcela mensal próximo ao que você consegue investir para alcançar seus
      planos.
    </p>
    <p>
      O valor acumulado não pode ser alterado nesta tela, apenas na destinação de patrimônio, onde atribuímos
      o patrimônio acumulado aos seus planos.
    </p>
    <p>Ao salvar, os valores serão atualizados em nosso banco de dados.</p> */}

      <IframeVimeo title="Gestão de Planos" videoId="1003782632?h=e3f97a7808" />
    </div>
    <div>
      <IframeVimeo title="Novo lançamento de Investimentos" videoId="1004612002?h=b61661ed68" />
    </div>
    <div>
      <IframeVimeo title="Novo lançamento de receita e despesa" videoId="1004612863?h=9dfd72ecf3" />
    </div>
  </Pagination>
)

export const HelpDestinacaoPatrimonio = () => (
  <Pagination>
    <div>
      <IframeVimeo title="Destinação de Patrimônio" videoId="1005518141?h=e7e2e00b6d" />
    </div>
  </Pagination>
)

export const HelpPatrimonio = () => {
  return (
    <Pagination>
      <div>
        <p>
          Nesta tela você vai inserir todo o seu patrimônio, seja financeiro ou imobilizado. Aqui será toda a
          visão dos seus bens.
        </p>
        <p>
          Abaixo encontram-se alguns gráficos da evolução dos seus investimentos. Grande parte dos ativos
          financeiros estão vinculados com inteligência para atualizar automaticamente.
        </p>
        <p>
          Cadastre todos os seus investimentos financeiros. Quando fizer novos aportes, basta entrar em “Novo
          Lançamento” no topo da página, escolher a aba “Investimentos” e escolher o ativo que deseja
          investir. Automaticamente o sistema reconhecerá o seu aporte.
        </p>

        <h4>Tutorial (Patrimônio):</h4>

        <IframeVimeo title="Patrimônio (Ajuda)" videoId="768239284" />
      </div>

      <div>
        <h4>Tutorial (Introdução Geral):</h4>

        <IframeVimeo title="Patrimônio - Introdução Geral (Ajuda)" videoId="803298081" />
      </div>

      <div>
        <h4>Tutorial (Integração de Investimentos):</h4>

        <IframeVimeo
          title="Patrimônio - Integração de Investimentos (Ajuda)"
          videoId="https://youtu.be/vHemGaumC3I"
        />
      </div>

      <div>
        <h4>Tutorial (Debêntures):</h4>

        <IframeVimeo title="Patrimônio - Debêntures (Ajuda)" videoId="803297760" />
      </div>

      <div>
        <h4>Tutorial (Ações / FIIs / ETFs):</h4>

        <IframeVimeo title="Patrimônio - Ações / FIIs / ETFs (Ajuda)" videoId="803297349" />
      </div>

      <div>
        <h4>Tutorial (Moedas):</h4>

        <IframeVimeo title="Patrimônio - Moedas (Ajuda)" videoId="803298179" />
      </div>

      <div>
        <h4>Tutorial (Fundos e Previdência):</h4>

        <IframeVimeo title="Patrimônio - Fundos e Previdência (Ajuda)" videoId="803298179" />
      </div>

      <div>
        <h4>Tutorial (Produto Customizado):</h4>

        <IframeVimeo title="Patrimônio - Produto Customizado (Ajuda)" videoId="803298257" />
      </div>

      <div>
        <h4>Tutorial (Poupança):</h4>

        <IframeVimeo title="Patrimônio - Poupança (Ajuda)" videoId="803298219" />
      </div>

      <div>
        <h4>Tutorial (Renda Fixa Pré Fixado):</h4>

        <IframeVimeo title="Patrimônio - Renda Fixa Pré Fixado (Ajuda)" videoId="803298523" />
      </div>

      <div>
        <h4>Tutorial (Renda Fixa Pós Fixado):</h4>

        <IframeVimeo title="Patrimônio - Renda Fixa Pós Fixado (Ajuda)" videoId="803298351" />
      </div>

      <div>
        <h4>Tutorial (Tesouro Direto):</h4>

        <IframeVimeo title="Patrimônio - Tesouro Direto (Ajuda)" videoId="803298649" />
      </div>
    </Pagination>
  )
}

export const HelpDestinacaoInvestimentos = () => (
  <div>
    <p>
      Aqui você irá determinar para onde seus investimentos mensais serão destinados. Ao realizar o
      investimento do mês ele será distribuído na proporção que você determinar para cada um dos seus planos.
    </p>
    <p>
      Ex: Se você determinar 30% de um fundo de ações para a aposentadoria e outros 70% para a compra de uma
      casa, ao alocar qualquer valor neste fundo a inteligência do Vista distribuirá nesta proporção aos seus
      planos.
    </p>

    <IframeVimeo title="Destinação de Investimentos (Ajuda)" videoId="768239591" />
  </div>
)

export const HelpDividas = () => (
  <Pagination>
    <div>
      <IframeVimeo title="Cadastro de dívidas" videoId="1006570274?h=5356dd067e" />
    </div>

    <div>
      <IframeVimeo title="Lançamento e atualização de dívidas" videoId="1005517217?h=a17e57a99f" />
    </div>
  </Pagination>
)

export const HelpGestaoOrcamento = () => (
  <Pagination>
    <div>
      <IframeVimeo
        title="Edição, Exclusão, Suspensão de Orçamentos e Categorias"
        videoId="1004607888?h=34ffe448d9"
      />
    </div>

    <div>
      <IframeVimeo title="Criação de uma nova categoria de orçamento" videoId="1004607365?h=018dbeb5dd" />
    </div>

    <div>
      <IframeVimeo title="Resumos das estimativas e realizados" videoId="1005516686?h=2d14cdb40a" />
    </div>

    <div>
      <IframeVimeo title="Visão dos orçamentos/grupos/categorias" videoId="1004613456?h=8eb7395026" />
    </div>

    <div>
      <IframeVimeo
        title="Descrição, ordenação de colunas e filtros do orçamento"
        videoId="1006569955?h=854239a3c9"
      />
    </div>

    <div>
      <IframeVimeo title="Notificação de atingimento de meta" videoId="1006570592?h=e37b167786" />
    </div>

    <div>
      <IframeVimeo title="Notificação de atingimento de meta" videoId="1008221871?h=c4114ae3e6" />
    </div>
  </Pagination>
)

export const HelpExtrato = () => (
  <Pagination>
    <div>
      <IframeVimeo
        title="Extrato download e utilização do excel “Padrão Vista”"
        videoId="1004606583?h=5d7ff773a9"
      />
    </div>

    <div>
      <IframeVimeo title="Extrato como fazer o Upload" videoId="1006569658?h=15056e53c6" />
    </div>

    <div>
      <IframeVimeo title="Extrato descrição geral" videoId="1006571710?h=8028669e10" />
    </div>

    <div>
      <IframeVimeo title="Extrato categorização das receitas e despesas" videoId="1006573118?h=4fb136d02c" />
    </div>
  </Pagination>
)

export const HelpEquilibrioFinanceiro = () => (
  <div>
    <p>Nesta tela você terá informações preciosas do seu Planejamento Financeiro.</p>

    <p>
      Determine metas e objetivos gerais, veja como está a sua evolução e quanto dinheiro está economizando e
      ganhando.
    </p>
  </div>
)

export const HelpMeioPagamento = () => (
  <div>
    <p>Nesta tela você terá informações preciosas do seu Planejamento Financeiro.</p>

    <p>
      Determine metas e objetivos gerais, veja como está a sua evolução e quanto dinheiro está economizando e
      ganhando.
    </p>

    <IframeVimeo title="Meio de pagamento (Ajuda)" videoId="893890362" />
  </div>
)

export const HelpIndependenciaFinanceira = () => (
  <Pagination>
    <div>
      {/* <p>Nesta tela você terá informações preciosas do seu Planejamento Financeiro.</p> */}
      <IframeVimeo title="Tutorial Independência Financeira" videoId="1005523055?h=49a07ad9a0" />
    </div>

    <div>
      <IframeVimeo title="Como atualizar o plano" videoId="1008221692?h=f279ae11a9" />
    </div>
  </Pagination>
)

export const HelpIntegracao = () => (
  <Pagination>
    <div>
      <IframeVimeo title="Integrações bancárias e de investimento" videoId="1005956062?h=0fcd3d74df" />
    </div>

    <div>
      <IframeVimeo title="Integrações bancárias e de investimento" videoId="1006571427?h=1b8f4f3109" />
    </div>
  </Pagination>
)

const HelpPatrimonioIntegracao = () => (
  <>
    <Pagination>
      <div>
        <IframeVimeo title="Patrimônio" videoId="1004624437?h=cedde2a11d" />
      </div>

      <div>
        <IframeVimeo title="Cadastro Manual de Patrimônio" videoId="1004617550?h=74a185757b" />
      </div>
    </Pagination>
  </>
)

export const HelpPlannerUsuarios = () => (
  <Pagination>
    <div>
      <IframeVimeo videoId="1003803339?h=ac2aaf05dd" title="Cadastrar novos usuários dentro de uma família" />
    </div>

    <div>
      <IframeVimeo videoId="1005515552?h=327d76e120" title="Registrar meios de Pagamento do usuário" />
    </div>

    <div>
      <IframeVimeo videoId="1005516190?h=96dbb27147" title="Configuração inicial" />
    </div>
  </Pagination>
)

export const MAP: MapProps = {
  '/home': HelpHome,
  '/planos-sonhos': HelpPlanosSonhos,
  '/gestao-planos-sonhos': HelpGestaoPlanos,
  '/destinacao-patrimonio': HelpDestinacaoPatrimonio,
  '/destinacao-investimentos': HelpDestinacaoInvestimentos,
  '/dividas': HelpDividas,
  '/orcamento': HelpGestaoOrcamento,
  '/extrato': HelpExtrato,
  '/equilibrio-financeiro': HelpEquilibrioFinanceiro,
  '/independencia-financeira': HelpIndependenciaFinanceira,
  '/integracoes': HelpIntegracao,
  '/meios-pagamento': HelpMeioPagamento,
  '/patrimonio': HelpPatrimonioIntegracao,
  '/patrimonio-integracao': HelpPatrimonioIntegracao,
  '/usuarios': HelpPlannerUsuarios
}
