import React, { FunctionComponent, useCallback } from 'react'
import Theme, { defaultTheme } from '../../domain/theme'
import { ThemeProvider } from 'styled-components'
import { getTheme } from '../../domain/theme/api/getTheme'

interface ContextValue {
  theme: Theme
  changeTheme: (themeName: string) => Promise<void>
}

interface Props {
  children: React.ReactNode
}

const Context = React.createContext<ContextValue>({
  theme: defaultTheme,
  changeTheme: async () => {}
})

const isDefaultTheme = (themeName: string) =>
  !themeName || themeName === 'default' || themeName === 'undefined' || themeName === 'null'

const Provider: FunctionComponent<Props> = ({ children }) => {
  const [usingDefaultTheme, setIsUsingDefaultTheme] = React.useState(true)
  const [theme, setTheme] = React.useState(defaultTheme)
  const changeTheme = useCallback(
    async (themeName: string) => {
      localStorage.setItem('theme', themeName)
      if (isDefaultTheme(themeName)) {
        if (!usingDefaultTheme) {
          setIsUsingDefaultTheme(true)
          setTheme(defaultTheme)
        } else {
          console.debug('Already using default theme, skipping theme change')
        }
        return
      }
      try {
        const newTheme = await getTheme(themeName)
        setIsUsingDefaultTheme(false)
        setTheme(newTheme)
      } catch (err) {
        console.error('Error setting new theme as', themeName, err)
      }
    },
    [usingDefaultTheme]
  )
  return (
    <Context.Provider value={{ theme, changeTheme }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </Context.Provider>
  )
}

export { Provider as ThemeProvider, Context as ThemeContext }
export type { Props as ThemeProviderProps, ContextValue as ThemeContextValue }
