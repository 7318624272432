import React from 'react'
import * as S from './styles'
import { IButtonNewEntry } from './types'
import { useCoreContext } from '../../../../../hooks/contexts/coreProvider'
// import { useLocalStorage } from '../../../../../hooks'
import { useTransactionContext } from '../../../../../hooks/contexts/transactionContext'

export const ButtonNewEntry = ({ onClick }: IButtonNewEntry) => {
  const { modal } = useTransactionContext()
  const { familiaId, tipoUsuario } = useCoreContext()
  // const { removeValue: removeEntryValue } = useLocalStorage('lancamentoEdicao')

  const handleClick = () => {
    onClick?.()
    // removeEntryValue()
    modal.show()
  }

  if (!familiaId) return <></>

  return (
    <S.NavItem visible={tipoUsuario === 2 || familiaId !== null}>
      <S.NavLink id="novo-lancamento" onClick={handleClick}>
        <S.Icon className="fas fa-star" />
        <S.Text className="nav-link-inner--text adaptartamanhoSpan">Novo Lançamento</S.Text>
      </S.NavLink>
    </S.NavItem>
  )
}
