import React, { useMemo, useState } from 'react'
import { FieldSelect } from '../FieldSelect'
import { Props } from './types'
import { useInitialInitialDataContext } from '../../../hooks/contexts/initialDataContext'

export const FieldSelectPatrimonioFinanceiroManual = ({
  selectedId,
  label = 'Patrimônio Financeiro *',
  id = 'investimentoPatrimonioFinanceiro',
  defaultPlaceholder,
  defaultValue,
  onSelect
}: Props) => {
  const [filter, setFilter] = useState('')
  const { allInvestments, isLoadingGetAllEquitys, allEquitysIsFetched } = useInitialInitialDataContext()
  const isManualData = allInvestments?.filter((item) => item.isManual)
  const withoutManualInvestments = allEquitysIsFetched && !isManualData?.length
  const defaultWithoutData = [{ label: 'Não existem investimentos manuais cadastrados', value: null }]

  const OPTIONS = useMemo(
    () =>
      isManualData?.map((investment) => ({
        value: {
          ...investment,
          category: 'Investimentos',
          dataCriacao: investment.createdAt,
          dataUltimaAtualizacao: investment.updatedAt,
          descricao: investment.description,
          familiaId: investment.familyId,
          id: investment.id
        },
        label: investment.description
      })) || [],
    [isManualData]
  )

  const selected = useMemo(
    () =>
      defaultValue && !!OPTIONS?.length
        ? OPTIONS?.find(({ value }) => value.id === String(defaultValue))
        : undefined,
    [defaultValue, OPTIONS]
  )

  const placeholder =
    (selectedId &&
      !!OPTIONS?.length &&
      OPTIONS?.find(({ value }) => value.id === String(selectedId))?.value.description) ||
    defaultPlaceholder ||
    'Selecione...'

  // console.log({ OPTIONS, withoutManualInvestments })

  return (
    <FieldSelect
      filter={filter}
      onFilter={setFilter}
      label={label}
      id={id}
      loading={isLoadingGetAllEquitys}
      // options={[]}
      // @ts-expect-error expected-error
      options={(!!OPTIONS?.length && OPTIONS) || (withoutManualInvestments && defaultWithoutData) || []}
      placeholder={placeholder}
      onChange={({ value }) => {
        // @ts-expect-error expected-error
        onSelect?.({ value })
      }}
      // @ts-expect-error expected-error
      selectedValue={selected?.value}
    />
  )
}
