import React, { forwardRef, useEffect, useRef } from 'react'
import * as S from './styles'
import SkyLight from 'react-skylight'

interface IModalV2 {
  open: boolean
  close?: () => void
  beforeOpen?: () => void
  title: string | React.ReactNode
  children: React.ReactNode
  className?: string
  usePrimary?: boolean
}

export const ModalV2 = forwardRef(
  ({ open, close, title, children, beforeOpen, className, usePrimary }: IModalV2, ref) => {
    const modalRef = useRef<SkyLight>(ref)

    useEffect(() => {
      if (open) {
        if (modalRef && !modalRef?.current?.state?.isVisible) {
          modalRef?.current?.show()
          const root = document.querySelector('html')
          if (root) root.style.overflow = 'hidden'
        }
        return
      }

      if (open && modalRef?.current?.state?.isVisible) {
        modalRef?.current?.hide()
        const root = document.querySelector('html')
        if (root) root.style.overflow = 'unset'
      }
    }, [modalRef, open])

    return (
      <S.Wrapper open={open} className={className} usePrimary={usePrimary}>
        <S.StyledSkyLight
          ref={modalRef}
          transitionDuration={150}
          beforeOpen={() => beforeOpen?.()}
          beforeClose={() => {
            const root = document.querySelector('html')
            if (root) root.style.overflow = 'unset'
            close?.()
          }}
          dialogStyles={{ borderRadius: '1rem', padding: '2rem' }}
          title={title}
        >
          {children}
        </S.StyledSkyLight>
      </S.Wrapper>
    )
  }
)
