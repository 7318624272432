// import { saveTransaction } from '../../../../../domain/lancamento/api/saveEntry'
// import $ from 'jquery'
import moment from 'moment'
import { IParseTransactionData } from './types'
import { getTransactionType } from '../../../../../domain/lancamento/lancamento'

// const getError = (data?: GetErrorProps) => {
//   let message = ''

//   switch (data?.exception?.Message) {
//     case 'Este ativo ainda não possui cotação nessa data. Tente novamente no próximo dia útil.':
//       message =
//         'Esse ativo ainda não possui cotação pois os dados são ' +
//         'atualizados no fechamento do dia anterior. ' +
//         'Tente no próximo dia útil lançar este investimento.'
//       break
//     default:
//       message = data?.exception?.Message ?? 'Erro ao salvar lançamento'
//       break
//   }
//   return message
// }

export const setFixedTime = (date: Date): Date => {
  const timeSettings = { hour: 12, minute: 0, second: 0, millisecond: 0 }

  if (!date) {
    console.log('! date', new Date(moment.utc().set(timeSettings).toISOString()))
    return new Date(moment.utc().set(timeSettings).toISOString())
  }

  // // @ts-expect-error date type
  // if (typeof date === 'string' && !date.includes('T')) {
  if (typeof date === 'string') {
    const newDate = moment(date, 'DD-MM-YYYY').toDate()
    return new Date(moment.utc(newDate).set(timeSettings).toISOString())
  }

  return new Date(moment.utc(new Date(date)).set(timeSettings).toISOString())
}

export const ParseTransactionData = ({
  lancamento: item,
  empresaId,
  familiaId,
  parceled
}: IParseTransactionData) => {
  const lancamento = item
  const { isParceled, isRepeated } = getTransactionType({ transactionType: lancamento?.tipoDeParcela ?? 99 })
  lancamento.patrimonioId = lancamento?.patrimonio?.id ?? lancamento.patrimonioId
  lancamento.categoriaId = lancamento?.categoria?.id ?? lancamento.categoriaId
  // lancamento.meioPagamentoId = lancamento?.meioPagamento?.id ?? lancamento?.categoria?.meioPagamentoPadrao?.id

  // if (lancamento?.categoria != null) {
  //   if (lancamento?.categoria?.orcamento?.tipoOrcamento === 3 && lancamento?.parcelas > 1) {
  //     lancamento.tipoDeParcela = 1
  //   } else {
  //     lancamento.tipoDeParcela = lancamento.parcelas !== null ? lancamento.tipoDeParcela : null
  //   }
  // }

  lancamento.parcelas = lancamento.parcelas !== null ? lancamento.parcelas : 1

  lancamento.empresaId = empresaId !== null ? empresaId : localStorage.getItem('empresa-id')
  lancamento.familiaId = familiaId

  //SE VENDA OU NAO RENDA PRE VALOR DA DATA DE VENCIMENTO É NULO
  if (
    lancamento.operacao === 1 ||
    lancamento.patrimonio == null ||
    (lancamento.patrimonio?.tipoInvestimento !== 3 && lancamento.patrimonio?.tipoInvestimento !== 6)
  ) {
    lancamento.dataVencimento = null
  }

  if (lancamento.quantidade != null && lancamento.valor != null) {
    if (lancamento.patrimonio != null) {
      if (
        lancamento.patrimonio.tipoInvestimento === 8 ||
        lancamento.patrimonio.tipoInvestimento === 10 ||
        lancamento.patrimonio.tipoInvestimento === 99
      ) {
        lancamento.valorCalculado =
          (lancamento.valor != null ? lancamento.valor : 0) *
          (lancamento.quantidade != null && lancamento.quantidade >= 0 ? lancamento.quantidade : 0)
      } else if (lancamento.patrimonio.tipoInvestimento === 4) {
        lancamento.valorCalculado =
          (lancamento.valor != null ? lancamento.valor : 0) /
          (lancamento.quantidade != null && lancamento.quantidade > 0 ? lancamento.quantidade : 1)
      }
    }
  }

  if (!lancamento.empresaId) {
    const id = localStorage.getItem('empresa-id')
    lancamento.empresaId = id ? Number(id) : null
  }
  if (typeof lancamento.empresaId === 'string') {
    lancamento.empresaId = Number(lancamento.empresaId)
  }

  lancamento.data = setFixedTime(lancamento.data)

  if ((isParceled || isRepeated) && Number(lancamento?.parcelas) > 1) {
    lancamento.parcelado = isParceled
    lancamento.valor =
      isRepeated || isParceled ? lancamento?.valorParcela || lancamento?.valor : lancamento?.valor
    lancamento.parcelas = isParceled || isRepeated ? lancamento?.parcelas : 1
    lancamento.valorParcela = isParceled || isRepeated ? lancamento?.valorParcela : 0
  }

  if (parceled === 1 || parceled === 2) {
    lancamento.tipoParceladoAlteracaoParcela = parceled
    lancamento.tipoParceladoAlteracaoParcelaValor = 1
  }

  return {
    id: lancamento.id,
    data: {
      accountId: lancamento.accountId,
      agrupamento: lancamento.agrupamento ?? null,
      ativoId: lancamento.ativoId,
      categoriaId: lancamento.categoriaId,
      corretagem: lancamento.corretagem,
      corretoraId: lancamento.corretoraId,
      cotizadoCorretamente: lancamento.cotizadoCorretamente,
      custoOperacional: lancamento.custoOperacional ?? '',
      data: lancamento.data,
      dataVencimento: lancamento.dataVencimento ?? null,
      descricao: lancamento.descricao,
      emolumentos: lancamento.emolumentos,
      empresaId: lancamento.empresaId,
      enableBudgetManager: lancamento.enableBudgetManager,
      familiaId: lancamento.familiaId,
      financialInstitution: lancamento.financialInstitution,
      financialInstitutionCode: lancamento.financialInstitutionCode,
      id: lancamento.id,
      idInstituicaoFinanceira: lancamento.idInstituicaoFinanceira ?? null,
      idMovimentacao: lancamento.idMovimentacao ?? null,
      idTransacaoBob: lancamento.idTransacaoBob,
      impostoRenda: lancamento.impostoRenda ?? '',
      iof: lancamento.iof ?? '',
      isManual: lancamento.isManual,
      meioPagamentoId:
        item.meioPagamentoId ?? lancamento.meioPagamento?.id ?? lancamento.categoria?.meioPagamentoPadrao?.id,
      nomeCategoria: lancamento.nomeCategoria,
      nomeMeioPagamento: lancamento.nomeMeioPagamento,
      nomeOrcamento: lancamento.nomeOrcamento,
      operacao: lancamento.operacao ?? null,
      orcamentoId: lancamento.orcamentoId,
      parcelas: lancamento.parcelas,
      patrimonio: lancamento.patrimonio ?? null,
      patrimonioAtivoId: lancamento.patrimonioAtivoId,
      patrimonioId: lancamento.patrimonioId,
      pluggyInvestmentId: lancamento.pluggyInvestmentId,
      pluggyItemId: lancamento.pluggyItemId,
      pluggyTransactionId: lancamento.pluggyTransactionId,
      precoCota: lancamento.precoCota,
      quantidade: lancamento.quantidade ?? '',
      statusCategorizacaoBob: lancamento.statusCategorizacaoBob,
      taxaAnual: lancamento.taxaAnual ?? '',
      taxaCustomizada: lancamento.taxaCustomizada ?? '',
      tipoDeParcela: lancamento.tipoDeParcela ?? 99,
      tipoMeioPagamento: lancamento.tipoMeioPagamento,
      tipoOrcamento: lancamento.tipoOrcamento,
      tipoParceladoAlteracaoParcela: lancamento.tipoParceladoAlteracaoParcela,
      tipoParceladoAlteracaoParcelaValor: lancamento.tipoParceladoAlteracaoParcelaValor,
      tipoRetirada: lancamento.tipoRetirada ?? null,
      unbudget: lancamento.unbudget,
      uncategorized: lancamento.uncategorized,
      valor: lancamento.valor,
      valorParcela: lancamento.valorParcela
    }
  }
  // globalThis.spinnerShow($)

  // await saveTransaction({ id: lancamento.id, data: lancamento })
  //   // @ts-expect-error expected-error
  //   .then(({ status, data }: { status: number; data?: GetErrorProps }) => {
  //     if (data?.exception?.Message) {
  //       console.error(data?.exception?.Message)
  //       onError?.(data?.exception?.Message)
  //       globalThis.spinnerHide($)
  //       return
  //     }

  //     if (status === 200) {
  //       onSuccess?.()
  //     } else {
  //       const message = getError(data)
  //       console.error(message)
  //       onError?.(message)
  //     }
  //     globalThis.spinnerHide($)
  //   })
  //   .catch((error) => {
  //     globalThis.spinnerHide($)
  //     const message = getError(error.response.data)
  //     console.error(message)
  //     onError?.(message)
  //   })
}
