import React from 'react'
import { NavLink as NavLinkRRD, Link } from 'react-router-dom'
import { PropTypes } from 'prop-types'
import Select from 'react-select'
import axios from 'axios'
import { CircularProgress } from '@material-ui/core'
import { Collapse, Navbar, NavItem, NavLink, Nav, Container, Row, Col } from 'reactstrap'
import Logo from '../../theme/logo'
import Fitas from '../../theme/fitas'
import WrapperForLinks from '../../theme/wrapperForLinks'
import BetaTag from '../Utils/BetaTag'
import { withContexts } from '../HOC'
import '../../assets/css/always-visible.css'
import * as S from './styles'
import { ModalV2 } from '../Modal/ModalV2'

class Sidebar extends React.Component {
  imagens = {}
  constructor(props) {
    super(props)
    this.activeRoute.bind(this)

    this.state = {
      collapseOpen: false,
      configuracoesCssDisplay: 'none',
      configuracoesCssDisplay2: 'none',
      configuracoesIcone: 'ni ni-bold-right',
      configuracoesIcone2: 'ni ni-bold-right',
      empresaId: localStorage.getItem('empresa-id'),
      familiaId: localStorage.getItem('familia-id') || null,
      userId: localStorage.getItem('usuario-id'),
      planejadorId: localStorage.getItem('planejador-id'),
      tipoUsuario: parseInt(localStorage.getItem('tipo-usuario')),
      planejadorPrincipal: localStorage.getItem('planejador-principal'),
      hasSelectedFamily: false,
      selectedFamily: {}
    }
  }

  async componentDidMount() {
    this.getFamilias()
    let themeLocalStorage = localStorage.getItem('theme')
    if (themeLocalStorage !== null) {
      this.props.themeContext.changeTheme(themeLocalStorage)
    }
    this.setState({
      selectedFamily: !!this.props.family
    })
  }

  getEmpresaFamilia = () => {
    if (this.state.familiaId !== null) {
      var url = global.server_api_new + '/familia/' + this.state.familiaId + '/buscaidempresa'

      try {
        axios.get(url).then((res) => {
          var idEmpresa = res.data.singleResult

          if (idEmpresa != null) {
            localStorage.setItem('empresa-id', idEmpresa)
          }
        })
      } catch (ex) {
        console.log(ex)
      }
    }
  }

  getFamilias = async () => {
    if (this.state.tipoUsuario > 1) return

    const familiesUrl = `${global.server_api_new}/v2/familia`
    const { data: families } = await axios.get(familiesUrl)
    let familias = []
    familias.push({ value: null, label: 'Voltar para Vista Planejador' })

    const sortedFamilies = families.sort(({ nome: firstName }, { nome: secondName }) => {
      if (firstName.toUpperCase() > secondName.toUpperCase()) return 1
      else if (firstName.toUpperCase() < secondName.toUpperCase()) return -1
      return 0
    })
    const options = sortedFamilies.map(({ id, nome }) => ({ value: id, label: nome }))
    for (const option of options) familias.push(option)
    this.setState({ familias })
  }

  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? 'active' : ''
  }

  toggleCollapse = () => {
    this.setState({
      collapseOpen: !this.state.collapseOpen
    })
  }

  closeCollapse = () => {
    this.setState({
      collapseOpen: false
    })
  }

  mostrarPopup = () => (
    <ModalV2
      // open={() => {
      //   window.scrollTo(0, 0)
      //   // this.preCarregarParaEdicao()
      // }}
      usePrimary={this.state.tipoUsuario > 1}
      open={this.state.modalSelecaoCliente}
      close={() => {
        this.setState({ modalSelecaoCliente: false })
      }}
      // dialogStyles={{ borderRadius: '1rem', padding: '2rem' }}
      title={
        <div style={{ opacity: this.state.hasSelectedFamily ? '0.3' : '1' }}>
          <div className="pop-up-title">
            <i className="ni ni-check-bold"></i>
            <h2>Seleção de Cliente</h2>
          </div>
        </div>
      }
    >
      <Row style={{ opacity: this.state.hasSelectedFamily ? '0.3' : '1' }}>
        <Col lg="12" xl="12">
          {this.montarDropDown(
            this.state.familias,
            'familia-select',
            this.mudarFamilia,
            this.state.familiaId,
            {
              disabled: this.state.hasSelectedFamily
            }
          )}
        </Col>
      </Row>
    </ModalV2>
    // <WrapperForModal usePrimary={this.state.tipoUsuario > 1}>
    //   <div className="trocar-familia">
    //     <SkyLight
    //       ref={(ref) => (this.modal = ref)}
    //       transitionDuration={0}
    //       beforeOpen={() => {
    //         window.scrollTo(0, 0)
    //         // this.preCarregarParaEdicao()
    //       }}
    //       afterClose={this.limparSelecao}
    //       dialogStyles={{ borderRadius: '1rem', padding: '2rem' }}
    //       title={
    //         <div style={{ opacity: this.state.hasSelectedFamily ? '0.3' : '1' }}>
    //           <div className="pop-up-title">
    //             <i className="ni ni-check-bold"></i>
    //             <h2>Seleção de Cliente</h2>
    //           </div>
    //         </div>
    //       }
    //     >
    //       <Row style={{ opacity: this.state.hasSelectedFamily ? '0.3' : '1' }}>
    //         <Col lg="12" xl="12">
    //           {this.montarDropDown(
    //             this.state.familias,
    //             'familia-select',
    //             this.mudarFamilia,
    //             this.state.familiaId,
    //             {
    //               disabled: this.state.hasSelectedFamily
    //             }
    //           )}
    //         </Col>
    //       </Row>
    //     </SkyLight>
    //   </div>
    // </WrapperForModal>
  )

  montarDropDown = (itens, id, changeMethod, selectedValue, { disabled = false }) => (
    <Select
      id={id}
      onChange={changeMethod}
      className="select-component"
      options={itens}
      defaultValue={itens != null ? itens[0] : ''}
      placeholder="Selecione..."
      disabled={disabled}
      value={
        itens != null && selectedValue != null
          ? itens.find((op) => {
              return op.value === selectedValue
            })
          : null
      }
    />
  )

  componentDidUpdate(_prevProps, prevState) {
    if (prevState.familiaId !== this.state.familiaId) {
      setTimeout(() => {
        window.location.href = `${window.location.origin}/v1/admin/home`
      }, 1000)
    }
  }

  // TODO: Move this code to a core context or a auth context
  mudarFamilia = (selectedOption) => {
    var familia = selectedOption.value
    const isAdmin = parseInt(this.state.tipoUsuario) === 0

    //Se é o admin tem que limpar a empresa da familia
    if (isAdmin) {
      // localStorage.removeItem('empresa-id')
      this.props.setEmpresaId(null)
    }

    localStorage.removeItem('dataInicio')
    localStorage.removeItem('dataFim')
    this.props.setFamiliaId(familia)

    this.setState({
      familiaId: familia,
      hasSelectedFamily: !!familia
    })
  }

  temPermissao = (perfisPermitidos) => {
    if (perfisPermitidos == null) return true

    if (!!this.state.familiaId && !perfisPermitidos.includes(2)) {
      return false
    }

    return perfisPermitidos.includes(parseInt(this.state.tipoUsuario))
    // for (var i = 0; i < perfisPermitidos.length; i++) {
    //   if (perfisPermitidos[i] === parseInt(this.state.tipoUsuario)) return true
    // }

    // return false
  }

  temPermissaoCliente = (perfisPermitidos, path) => {
    if (perfisPermitidos == null) return true

    for (var i = 0; i < perfisPermitidos.length; i++) {
      if (perfisPermitidos[i] === parseInt(this.state.tipoUsuario)) return true
    }

    return false
  }

  createLinks = (routes) => {
    if (routes == null) {
      return <></>
    }

    return routes.map((prop, key) => {
      //console.log("prop: ", prop.path, "key", key);
      if (
        prop.hideOnSidebar === true || //Se é para esconder no sidebar
        prop.configurationMenu === true || //Se é menu de configuracao
        prop.userConfigurationMenu === true || //Se é menu de configuracao de usuario
        (prop.perfis != null && !this.temPermissao(prop.perfis)) || //se tipo usuario incompatível com perfil do menu
        (prop.planejadorPrincipal === true &&
          this.state.tipoUsuario === 1 &&
          parseInt(this.state.planejadorPrincipal) !== 1)
      ) {
        //Se necessita de planejador principal (caso planejador)
        return <div key={key}></div>
      }

      //let pessoasTeste = [22,82,187,193,237,415,749,1164,1165,1185,87,263,282,334,343,349,350,423,424,712,749,696,81, 761];
      //prod - id de usuario
      //let pessoasTeste = [22,82,187,193,232,237,415,749,1164,1165,1185,242,269,301,893,696,81, 761, 263, 347, 1375];
      //hml - id de usuario
      // let pessoasTeste = [
      //   187, 263, 282, 334, 343, 349, 350, 423, 424, 712, 749, 696, 81, 1378, 709, 1380, 1382, 1384, 1387
      // ]

      // let user = parseInt(localStorage.getItem('usuario-id'))

      if (!this.state.familiaId) {
        return <div key={key}></div>
      } else if (prop.header) {
        return (
          <div style={{ paddingLeft: '1.4rem', fontSize: '1.25rem' }} key={key}>
            <h4 style={{ color: 'white', marginBottom: '0.3rem' }}>
              <i style={{ marginRight: '.2rem', marginTop: 15 }} className={prop.icon} /> {prop.header}
            </h4>
          </div>
        )
      }

      if (prop.redirect) {
        const { url } = prop.redirect()
        return (
          <WrapperForLinks tipoUsuario={this.state.tipoUsuario} key={key}>
            <a href={url} title={prop.name} className="nav-link">
              <i className={prop.icon} style={{ fontSize: 10, color: '#FFF' }} />
              {prop.name}
              {prop.betaTag && <BetaTag />}
            </a>
          </WrapperForLinks>
        )
      }

      return (
        <WrapperForLinks tipoUsuario={this.state.tipoUsuario} key={key}>
          <NavItem key={key}>
            <S.SidebarMenuLink
              to={prop.layout + prop.path}
              tag={NavLinkRRD}
              onClick={this.closeCollapse}
              style={{ paddingRight: 0 }}
              data-inactive={prop.inactive}
            >
              <i className={prop.icon} style={{ fontSize: 10, color: '#FFF' }} />
              {prop.name}
              {prop.betaTag && <BetaTag />}
            </S.SidebarMenuLink>
          </NavItem>
        </WrapperForLinks>
      )
    })
  }

  createConfigLinks = (routes) => {
    if (routes == null) {
      return <></>
    }

    const { authContext, familiaId, empresaId } = this.props || {}
    const { token, isManager, isPlanner, isSupport, plannerId, isPlanned } = authContext || {}
    const rawShowFamilyPlanner = ['/usuarios', '/meios-pagamento', '/wizard']

    return routes.map((prop, key) => {
      //console.log("prop: ", prop.path, "key", key);
      let rawTest = this.state.familiaId !== null && rawShowFamilyPlanner.includes(prop.path)
      if (
        (rawTest === false &&
          prop.name === 'Clientes' &&
          this.state.familiaId !== null &&
          this.state.familiaId !== undefined) ||
        prop.hideOnSidebar === true || //Se é para esconder no sidebar
        prop.configurationMenu !== true || //Se não é menu de configuracao
        (prop.perfis != null && !this.temPermissao(prop.perfis)) || //Se tipo usuario incompatível com perfil do menu
        (prop.onlyManager === true && !this.props.authContext.isManager) ||
        (prop.planejadorPrincipal === true &&
          parseInt(this.state.tipoUsuario) === 1 &&
          parseInt(this.state.planejadorPrincipal) !== 1)
      ) {
        //Se necessita de planejador principal (caso planejador)
        return <div key={key}></div>
      }

      if (this.state.familiaId !== null) {
        if (prop.verificacaoFamiliaSelecionada === true) {
          return this.renderNavItem(prop, key)
        }
      } else {
        if (!rawShowFamilyPlanner.includes(prop.path)) return this.renderNavItem(prop, key)
      }
    })
  }

  renderNavItem = (prop, key) => {
    // if (prop.path === '/tutoriais') {
    //   return (
    //     <NavItem key={key}>
    //       <NavLink
    //         data-inactive={prop.inactive}
    //         target="_blank"
    //         className="active"
    //         alt="teste"
    //         href="https://materiais.meuvista.com/tutoriais"
    //       >
    //         <i className={prop.icon} style={{ paddingRight: 10 }} />
    //         Tutoriais
    //       </NavLink>
    //     </NavItem>
    //   )
    // }
    return (
      <WrapperForLinks tipoUsuario={this.state.tipoUsuario} key={key}>
        <NavItem key={key}>
          <NavLink
            data-inactive={prop.inactive}
            to={prop.layout + prop.path}
            tag={NavLinkRRD}
            onClick={this.closeCollapse}
            activeClassName="active"
          >
            <i className={prop.icon} style={{ paddingRight: 10 }} />
            {prop.name}
          </NavLink>
        </NavItem>
      </WrapperForLinks>
    )
  }

  createUserConfigLinks = (routes) => {
    if (routes == null) {
      return <></>
    }

    return routes.map((prop, key) => {
      if (
        prop.hideOnSidebar === true || //Se é para esconder no sidebar
        prop.userConfigurationMenu !== true || //Se não é menu de configuracao
        (prop.perfis != null && !this.temPermissao(prop.perfis)) || //Se tipo usuario incompatível com perfil do menu
        (prop.planejadorPrincipal === true &&
          this.state.tipoUsuario === 1 &&
          parseInt(this.state.planejadorPrincipal) !== 1)
      ) {
        //Se necessita de planejador principal (caso planejador)
        return <div key={key}></div>
      }

      return (
        <NavItem key={key}>
          <NavLink
            to={prop.layout + prop.path}
            tag={NavLinkRRD}
            onClick={this.closeCollapse}
            activeClassName="active"
            data-inactive={prop.inactive}
          >
            <i className={prop.icon} />
            {prop.name}
          </NavLink>
        </NavItem>
      )
    })
  }

  trocarVisibilidadeConfiguracoes = () => {
    var novoValor = 'block'
    var novoIcone = 'ni ni-bold-down'

    if (this.state.configuracoesCssDisplay !== 'none') {
      novoValor = 'none'
      novoIcone = 'ni ni-bold-right'
    }

    this.setState({
      configuracoesCssDisplay: novoValor,
      configuracoesIcone: novoIcone
    })
  }

  trocarVisibilidadeConfiguracoes2 = () => {
    var novoValor = 'block'
    var novoIcone = 'ni ni-bold-down'

    if (this.state.configuracoesCssDisplay2 !== 'none') {
      novoValor = 'none'
      novoIcone = 'ni ni-bold-right'
    }

    this.setState({
      configuracoesCssDisplay2: novoValor,
      configuracoesIcone2: novoIcone
    })
  }

  render() {
    const { routes, logo, id, position } = this.props
    let navbarBrandProps
    if (logo && logo.innerLink) {
      navbarBrandProps = {
        to: logo.innerLink,
        tag: Link
      }
    } else if (logo && logo.outterLink) {
      navbarBrandProps = {
        href: logo.outterLink,
        target: '_blank'
      }
    }

    let familyImage = '/v1/static/media/familia.b63f190f.png'

    if (this.props.familyImage) {
      familyImage = this.props.familyImage
    }

    return (
      <>
        {this.mostrarPopup()}
        <Navbar
          className={`navbar-vertical ${position} navbar-light`}
          expand="md"
          id={id}
          style={{
            overflowX: 'hidden',
            background:
              this.state.tipoUsuario <= 1
                ? this.props.themeContext.theme.colors?.backgroundSidebarManager ||
                  this.props.themeContext.theme.colors.secondary
                : this.props.themeContext.theme.colors.primary
          }}
        >
          <S.Container>
            {/* Toggler */}
            <button className="navbar-toggler" type="button" onClick={this.toggleCollapse}>
              <span className="navbar-toggler-icon" />
            </button>
            {/* Brand */}
            {logo ? (
              <S.Logo
                $width={
                  this.props.themeContext.theme?.sizes?.web?.x !== undefined &&
                  this.props.themeContext.theme?.sizes?.web?.x !== ''
                    ? this.props.themeContext.theme?.sizes?.web?.x
                    : '100%'
                }
              >
                <Logo userIsClient={parseInt(this.state.tipoUsuario) === 2} />
              </S.Logo>
            ) : null}
            {/* Tipo Usuario */}
            {parseInt(this.state.tipoUsuario) === 2 ? '' : <Fitas />}
            <div
              className="family-background"
              style={{
                position: 'relative',
                display: 'flex',
                width: '97.5%',
                height: 70,
                justifyContent: 'center',
                paddingLeft: 45,
                background:
                  parseInt(this.state.tipoUsuario) === 1
                    ? this.props.themeContext.theme.colors?.backgroundSidebarManagerDarker ||
                      this.props.themeContext.theme.colors.secondaryDarker
                    : this.props.themeContext.theme.colors.primaryDarker
              }}
            >
              <CircularProgress
                size={24}
                style={
                  parseInt(this.state.tipoUsuario) <= 1 &&
                  this.state.familias == null &&
                  this.state.familiaId == null
                    ? {
                        float: 'left',
                        position: 'absolute',
                        left: '140px',
                        top: '20px'
                      }
                    : { display: 'none' }
                }
              />
              <div
                className="family-change"
                style={
                  parseInt(this.state.tipoUsuario) <= 1
                    ? {
                        background:
                          this.props.themeContext.theme.colors?.backgroundSidebarManagerDarker ||
                          this.props.themeContext.theme.colors.secondaryDarker,
                        position: 'absolute',
                        right: 0,
                        zIndex: -1
                      }
                    : {
                        background: this.props.themeContext.theme.colors.primaryDarker,
                        position: 'absolute',
                        right: 0,
                        zIndex: -1
                      }
                }
                onClick={() => {
                  if (
                    parseInt(this.state.tipoUsuario) <= 1 &&
                    (this.state.familias != null || this.state.familiaId != null)
                  )
                    // global.showModal(this.modal)
                    this.setState({ modalSelecaoCliente: true })
                }}
              >
                <NavLink
                  style={
                    parseInt(this.state.tipoUsuario) <= 1
                      ? {
                          background:
                            this.props.themeContext.theme.colors?.backgroundSidebarManagerDarker ||
                            this.props.themeContext.theme.colors.secondaryDarker
                        }
                      : {
                          display: 'none',
                          background: this.props.themeContext.theme.colors.primaryDarker
                        }
                  }
                >
                  <i className="fas fa-chevron-right" style={{ fontSize: 15, color: '#ffffff' }}></i>
                </NavLink>
                <NavLink
                  style={
                    parseInt(this.state.tipoUsuario) <= 1
                      ? {
                          display: 'none',
                          background:
                            this.props.themeContext.theme.colors?.backgroundSidebarManagerDarker ||
                            this.props.themeContext.theme.colors.secondaryDarker
                        }
                      : { background: this.props.themeContext.theme.colors.primaryDarker }
                  }
                >
                  <i className="fa fa-refresh" style={{ fontSize: '20px', color: '#ffffff' }}></i>
                </NavLink>
              </div>
              <div>
                <button
                  className="navbar-toggler"
                  type="button"
                  id="atualizar-familias-sidebar"
                  onClick={() => this.getFamilias()}
                >
                  Atualizar Famílias
                </button>
              </div>
              <div
                id="logo-familia"
                style={{
                  backgroundImage: `url(${familyImage})`,
                  position: 'absolute',
                  left: 0
                }}
              ></div>
              <div className="family-name" style={{ fontSize: '.9rem', paddingLeft: 0 }}>
                <b>
                  {parseInt(this.state.tipoUsuario) <= 1 &&
                  (!this.props.family || (!!this.props.family && !this.props.family.nome)) &&
                  this.state.familias != null ? (
                    <label style={{ marginBottom: 0, marginLeft: 10 }}>Selecione um cliente</label>
                  ) : (
                    this.props.family.nome
                  )}
                </b>
              </div>
            </div>
            {/* Collapse */}
            <Collapse navbar isOpen={this.state.collapseOpen} className="always-visible">
              {/* Navigation */}
              <button
                className="navbar-toggler navbar-toggler-icon"
                type="button"
                onClick={this.toggleCollapse}
                style={{
                  backgroundColor:
                    this.props.themeContext.theme.colors?.backgroundSidebarManagerDarker ||
                    this.props.themeContext.theme.colors.secondaryDarker,
                  width: 30,
                  height: 30
                }}
              ></button>
              <Nav navbar>{this.createLinks(routes)}</Nav>
              {/* Divider */}
              <hr className="my-3" />
              {/* Heading */}
              <h6
                className="navbar-heading text-muted"
                onClick={() => this.trocarVisibilidadeConfiguracoes()}
                style={{ width: '100%', marginLeft: '-10px' }}
              >
                <label style={{ marginBottom: 0, color: '#FFF' }}>Configurações</label>
                <i
                  className={this.state.configuracoesIcone}
                  style={{
                    fontSize: '15px',
                    float: 'left',
                    marginTop: '1px',
                    marginRight: '5px',
                    color: '#FFF'
                  }}
                />
              </h6>
              {/* Navigation */}
              <Nav navbar style={{ display: this.state.configuracoesCssDisplay }}>
                {this.createConfigLinks(routes)}
              </Nav>
            </Collapse>
          </S.Container>
        </Navbar>
      </>
    )
  }
}

Sidebar.defaultProps = {
  routes: [{}]
}

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired
  })
}

export default withContexts(Sidebar)
