import React from 'react'
import { NavLink as NavLinkRRD, Link, Redirect } from 'react-router-dom'
import SkyLight from 'react-skylight'
import { registerLocale } from 'react-datepicker'
import br from 'date-fns/locale/pt-BR'
import 'react-datepicker/dist/react-datepicker.css'
import Select from 'react-select'
import axios from 'axios'
import $ from 'jquery'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'
import PlanejadorService from '../../../services/PlanejadorService'
import { Navbar, NavLink, NavItem, Row, Col } from 'reactstrap'
import { noticeError } from '../../../services/Monitoring'
import '../adaptarMedia.css'
import { ThemeContext } from '../../../hooks/contexts/themeContext'
import ButtonNotesModal from '../../Buttons/ButtonNotesModal'
import { createDatePicker } from './components/createDatepicker'
// import { getPatrimonioAtivosRendaFixa } from './components/getPatrimonioAtivosRendaFixa'
import { paginaBug, reportar } from './constants'
import { HelpModal, HelpModalButton } from '../../Modal/HelpModal'
import { ModalNewTransaction } from './components/ModalNewTransaction'
import { useInitialInitialDataContext } from '../../../hooks/contexts/initialDataContext'
// import { RedirectToLogin, resetEssentialsLocalStorage } from '../../AuthRoute/utils'
import { useAuth } from '../../../hooks/contexts/authContext'
import { ButtonNewEntry } from './components/ButtonNewEntry'
import { useCoreContext } from '../../../hooks/contexts/coreProvider'
import { useSelectComboContext } from '../../../hooks/contexts/selectComboContext'
import * as S from './styles'

registerLocale('br', br)

function withManualInvestments(WrappedComponent) {
  return function (props) {
    const auth = useAuth()
    const { reset } = auth || {}
    const { tipoUsuario, familiaId } = useCoreContext()
    const {
      manualInvestments,
      isManualInvestmentsError,
      manualInvestmentsError,
      orcamentosFiltro,
      meiosPagamentos,
      categoriasFiltro,
      patrimoniosAtivosRendaFixa,
      isLoading,
      refetchAll
    } = useInitialInitialDataContext()
    const { MEIOS_PAGAMENTOS_OPTIONS, ORCAMENTOS } = useSelectComboContext()

    if (isLoading) {
      return <></>
    }

    return (
      <WrappedComponent
        manualInvestments={manualInvestments}
        isManualInvestmentsError={isManualInvestmentsError}
        manualInvestmentsError={manualInvestmentsError}
        orcamentos={orcamentosFiltro}
        categorias={categoriasFiltro}
        meiosPagamentos={meiosPagamentos}
        MEIOS_PAGAMENTOS_OPTIONS={MEIOS_PAGAMENTOS_OPTIONS}
        patrimoniosAtivosRendaFixa={patrimoniosAtivosRendaFixa}
        resetJwt={reset}
        refetchAll={refetchAll}
        familyId={familiaId}
        tipoUsuario={tipoUsuario}
        ORCAMENTOS={ORCAMENTOS}
        {...props}
      />
    )
  }
}

class AdminNavbar extends React.Component {
  static contextType = ThemeContext
  moment = require('moment')
  cadastroLancamento = null
  state = {
    alertEnvioForm: false,
    cadastroLancamento: {
      isVisible: false
    },
    categorias: [],
    patrimonios: [],
    orcamentos: [],
    edicao: false,
    familiaId: null,
    meiosPagamento: [],
    passoAtual: 1,
    patrimonio: [],
    patrimonioAtivos: {},
    patrimonioAtivosCombo: [],
    patrimonioAtivosDados: {},
    saveEntry: {
      open: false
    },
    helpModal: {
      userType: undefined,
      open: false
    },
    item: {
      agrupamento: null,
      ativo: null,
      categoria: null,
      custoOperacional: '',
      data: null,
      descricao: '',
      editRepeated: false,
      editingTransaction: false,
      idInstituicaoFinanceira: null,
      idMovimentacao: null,
      impostoRenda: '',
      impostoRendaFormatado: '',
      iof: '',
      iofFormatado: '',
      meioPagamento: null,
      operacao: null,
      orcamento: null,
      parcelas: 1,
      patrimonio: null,
      quantidade: '',
      quantidadeFormatado: '',
      taxaAnual: '',
      taxaAnualFormatada: '',
      taxaCustomizada: '',
      taxaCustomizadaFormatada: '',
      tipoDeParcela: null,
      tipoRetirada: null,
      valor: '',
      valorFormatado: '',
      videoURL: 'https://player.vimeo.com/video/518358268'
    }
  }

  constructor(props) {
    super(props)
    this.scriptMeuVistaAI = null
  }

  // creates the links that appear in the Rightbar
  createLinks = (routes) => {
    if (routes == null) return <></>

    return routes.map((prop, key) => {
      return (
        <NavItem key={key}>
          <NavLink
            to={prop.layout + prop.path}
            tag={NavLinkRRD}
            onClick={this.closeCollapse}
            activeClassName="active"
          >
            {prop.name}
            <i className={prop.icon} />
          </NavLink>
        </NavItem>
      )
    })
  }

  setItemOnState = (item, callback) => {
    if (callback) this.setState({ item }, callback())
    else this.setState({ item })
  }

  gerarDatePicker = (id, callback, defaultDate) => {
    const updateState = (state) => this.setState(state)
    return createDatePicker(id, callback, defaultDate, this.state, updateState, this.moment)
  }

  buscarPatrimonioAtivosRendaFixa = () => {
    // const url = `${global.server_api_new}/patrimonio/patrimonioAtivo/${this.props.familyId}`
    const { patrimoniosAtivosRendaFixa } = this.props
    const patrimonioAtivos = {}
    const patrimonioAtivosDados = {}

    if (Array.isArray(patrimoniosAtivosRendaFixa) && patrimoniosAtivosRendaFixa.length > 0) {
      patrimoniosAtivosRendaFixa.forEach((item) => {
        if (patrimonioAtivos[item.patrimonioId] == null) {
          patrimonioAtivos[item.patrimonioId] = []
        }

        patrimonioAtivosDados[item.id] = []
        patrimonioAtivosDados[item.id].push(item.quantidade)
        patrimonioAtivosDados[item.id].push(item.total)

        patrimonioAtivos[item.patrimonioId].push({
          value: item.id,
          label: item.descricao
        })
      })
    }

    this.setState({ patrimonioAtivosDados: patrimonioAtivosDados })
    this.setState({ patrimonioAtivos: patrimonioAtivos })
    // const updateState = (state) => this.setState(state)
    // getPatrimonioAtivosRendaFixa({ updateState, url })
  }

  // TODO: Apply method with identifier
  atualizarEntidadesDeDominio = (fieldKey, identificador) => {
    const { patrimoniosFinancialIntitution } = this.props
    // var url = global.server_api_new + '/patrimonio/metodosAuxiliares/porIdentificador'

    // var data = {
    //   filtro: identificador
    // }

    // axios.post(url, data).then((res) => {
    // const itens = res.data.data

    var combo = []

    if (Array.isArray(patrimoniosFinancialIntitution)) {
      patrimoniosFinancialIntitution.forEach((item) => {
        combo.push({
          value: item.key,
          label: item.value
        })
      })
    }

    this.setState({ [fieldKey]: combo })
    // })
  }

  atualizarOrcamentos = () => {
    const { ORCAMENTOS, orcamentos } = this.props || {}
    const { RECEITAS_OPTIONS, DESPESAS_OPTIONS } = ORCAMENTOS || {}

    // if (!Array.isArray(orcamentos)) return

    // var orcamentoDeDespesaCombo = [
    //   {
    //     label: 'Selecione...',
    //     value: null
    //   }
    // ]

    // var orcamentoDeReceitaCombo = [
    //   {
    //     label: 'Selecione...',
    //     value: null
    //   }
    // ]

    // if (orcamentos != null) {
    //   orcamentos.forEach((item) => {
    //     //Apenas orcamentos de receitas
    //     if (item.tipoOrcamento === 0 && item.suspenso === 0) {
    //       orcamentoDeReceitaCombo.push({
    //         value: item,
    //         label: item.nome
    //       })
    //     }
    //     //Apenas orcamentos de despesas
    //     else if (item.tipoOrcamento === 1 && item.suspenso === 0) {
    //       orcamentoDeDespesaCombo.push({
    //         value: item,
    //         label: item.nome
    //       })
    //     }
    //   })
    // }

    // if (orcamentoDeReceitaCombo.length === 1) {
    //   orcamentoDeReceitaCombo[0].label = 'Nenhuma categoria de receita ativa encontrada'
    // }

    // if (orcamentoDeDespesaCombo.length === 1) {
    //   orcamentoDeDespesaCombo[0].label = 'Nenhuma categoria de despesa ativa encontrada'
    // }

    this.setState({ orcamentos })
    this.setState({ orcamentoDeDespesaCombo: DESPESAS_OPTIONS })
    this.setState({ orcamentoDeReceitaCombo: RECEITAS_OPTIONS })
  }

  atualizaMeiosPagamento = () => {
    if (!this.props.familyId) return
    const { MEIOS_PAGAMENTOS_OPTIONS, meiosPagamentos } = this.props || []
    var meiosPagamentoCombo = []

    if (Array.isArray(meiosPagamentos)) {
      meiosPagamentos.forEach((meio) => {
        meiosPagamentoCombo.push({
          value: meio,
          label: meio.nome
        })
      })
    }

    this.setState({ meiosPagamento: meiosPagamentos })
    this.setState({ meiosPagamentoCombo: MEIOS_PAGAMENTOS_OPTIONS })
  }

  atualizaCategorias = () => {
    const { manualInvestments, categorias } = this.props || []
    let categoriasDeInvestimentoCombo = []

    if (Array.isArray(manualInvestments) && manualInvestments.length > 0) {
      manualInvestments.forEach((item) => {
        categoriasDeInvestimentoCombo.push({
          value: item,
          label: item.description
        })
      })
    }

    var categoriasDeReceitasCombo = []
    var categoriasDeDespesasCombo = []
    var categoriasDeDividaCombo = []

    if (Array.isArray(categorias) && categorias.length > 0) {
      categorias.forEach((c) => {
        if (!!c.orcamento && !!c.orcamento.tipoOrcamento) {
          if (c.orcamento.tipoOrcamento === 2 && c.suspenso === 0 && c.orcamento.suspenso === 0) {
            categoriasDeInvestimentoCombo.push({
              value: c,
              label: c.nome
            })
          }
          //Se orcamento de divida
          else if (c.orcamento.tipoOrcamento === 3 && c.suspenso === 0 && c.orcamento.suspenso === 0) {
            categoriasDeDividaCombo.push({
              value: c,
              label: c.nome
            })
          }
        }
      })

      if (categoriasDeReceitasCombo.length === 0) {
        categoriasDeReceitasCombo.push({
          label: 'Selecione um orçamento...',
          value: null
        })
      }

      if (categoriasDeDespesasCombo.length === 0) {
        categoriasDeDespesasCombo.push({
          label: 'Selecione um orçamento...',
          value: null
        })
      }

      if (categoriasDeInvestimentoCombo.length === 0) {
        categoriasDeInvestimentoCombo.push({
          label: 'Nenhuma categoria de investimento cadastrada e/ou ativa...',
          value: null
        })
      }

      if (categoriasDeDividaCombo.length === 0) {
        categoriasDeDividaCombo.push({
          label: 'Nenhuma categoria de dívida cadastrada e/ou ativa...',
          value: null
        })
      }

      this.setState({ categorias })
      this.setState({ categoriasDeReceitasCombo })
      this.setState({ categoriasDeDespesasCombo })
      this.setState({ categoriasDeInvestimentoCombo })
      this.setState({ categoriasDeDividaCombo })
    }
    // })
  }

  atualizarPatrimonios = () => {
    const { manualInvestments } = this.props || []
    this.setState({ patrimonio: manualInvestments })
  }

  componentDidMount() {
    this.PlanejadorService = new PlanejadorService()
    this.fileInput = React.createRef()
    this.setState(
      {
        empresaId: localStorage.getItem('empresa-id'),
        familiaId: localStorage.getItem('familia-id'),
        planejadorPrincipal: localStorage.getItem('planejador-principal'),
        planejadorId: localStorage.getItem('planejador-id'),
        tipoUsuario: localStorage.getItem('tipo-usuario'),
        categorias: this.props.categorias,
        meiosPagamentos: this.props.meiosPagamentos,
        orcamentos: this.props.orcamentos,
        patrimonios: this.props.manualInvestments,
        patrimoniosAtivosRendaFixa: this.props.patrimoniosAtivosRendaFixa
      },
      function () {
        this.atualizarDados(false)
      }
    )
    this.lancamentosCampos('inicio')

    if (window.location.href.endsWith('/admin')) {
      window.location.href = '/'
    }

    if (parseInt(localStorage.getItem('tipo-usuario')) === 1) {
      this.MeuVistaAiModal()
    }
  }

  componentWillUnmount() {
    this.RemoverMeuVistaAiModal()
  }

  componentDidUpdate(prevPros, prevState) {
    if (localStorage.getItem('jwt') === null) {
      this.setRedirect()
      return
    }

    // if (prevState.familiaId !== this.props.familyId) {
    //   this.props.refetchAll()
    // }
  }

  setRedirect = () => {
    this.setState({
      redirect: true
    })
  }

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to="/auth/login" />
    }
  }

  atualizarDados = (apenasVista) => {
    if (this.props.familyId !== null) {
      // this.atualizarPatrimonios()
      this.atualizarOrcamentos()
      this.atualizaCategorias()
      // this.atualizaMeiosPagamento()
      this.buscarPatrimonioAtivosRendaFixa()

      // if (apenasVista !== true) {
      //   this.atualizarEntidadesDeDominio('instituicoesFinanceiras', 'instituicoesFinanceiras')
      // }
    }
  }

  lancamentosCampos = (tipo) => {
    if (tipo !== 'inicio') {
      $('.lancamento-opcoes').removeClass('active')
    }

    $('.lancamento').hide()

    if (tipo === 'receita') {
      $('.receita').show()
      if (tipo !== 'inicio') {
        $($('.lancamento-opcoes')[0]).addClass('active')
      }
    } else if (tipo === 'despesa' || tipo === 'inicio') {
      $('.despesa').show()
      $($('.lancamento-opcoes')[1]).addClass('active')
    } else if (tipo === 'investimento') {
      $('.investimento').show()
      $($('.lancamento-opcoes')[2]).addClass('active')
    } else if (tipo === 'divida') {
      $('.divida').show()
      $($('.lancamento-opcoes')[3]).addClass('active')
    }
  }

  montarDropDown = (itens, id, changeMethod, selectedValue) => (
    <Select
      id={id}
      onChange={changeMethod}
      className="select-component"
      options={itens}
      defaultValue={itens != null ? itens[0] : ''}
      placeholder="Selecione..."
      value={
        itens != null && selectedValue != null
          ? itens.find((op) => {
              if (op.value != null && op.value['id'] != null && selectedValue['id'] != null) {
                return parseInt(op.value['id']) === parseInt(selectedValue['id'])
              } else {
                //ToString para a comparacao nao dar erro e pq pode ter string
                return op.value != null && op.value.toString() === selectedValue.toString()
              }
            })
          : null
      }
    />
  )

  // Abrir modal de lançamentos
  // PASSO 1
  preCarregarParaEdicao = () => {
    this.atualizaMeiosPagamento()
    try {
      var lancamentoEdicao = localStorage.getItem('lancamentoEdicao')

      if (!!lancamentoEdicao) {
        lancamentoEdicao = JSON.parse(lancamentoEdicao)

        this.setState({ edicao: true })

        var categoriaId = lancamentoEdicao.categoriaId //lancamentoEdicao.categoria.id;
        let categorias
        if (this.state.categorias != null) {
          categorias = this.state.categorias.filter((categoria) => {
            return categoriaId === categoria.id
          })
        }

        //Preenche combo de categorias de receita ou despesa
        if (
          categorias != null &&
          categorias[0] != null &&
          categorias[0].orcamento != null &&
          categorias[0].orcamento.tipoOrcamento < 2
        ) {
          this.handleChangeOrcamento({ value: categorias[0].orcamento })
        }

        var meioPagamentoId = lancamentoEdicao.meioPagamentoId
        var meioPagamentos = this.props.meiosPagamentos.filter((meioPagamento) => {
          return meioPagamentoId === meioPagamento.id
        })

        var patrimonioId = lancamentoEdicao.PatrimonioId
        let patrimonios = null
        if (this.state.patrimonio) {
          patrimonios = this.state.patrimonio.filter((patrimonio) => {
            return patrimonioId === patrimonio.id
          })
        }

        var data = this.moment(lancamentoEdicao.data, 'DD/MM/YYYY').toDate()

        var dataVencimento =
          lancamentoEdicao.dataVencimento != null
            ? this.moment(lancamentoEdicao.dataVencimento, 'YYYY-MM-DDThh:mm:ss').toDate()
            : null

        if (!!patrimonios && Array.isArray(patrimonios) && patrimonios.length > 0) {
          var tipoInvestimento = patrimonios[0].tipoInvestimento

          if (tipoInvestimento === 8 || tipoInvestimento === 10 || tipoInvestimento === 99) {
            lancamentoEdicao.valor =
              lancamentoEdicao.valor != null && lancamentoEdicao.valor > 0
                ? lancamentoEdicao.valor /
                  (lancamentoEdicao.quantidade != null && lancamentoEdicao.quantidade > 0
                    ? lancamentoEdicao.quantidade
                    : 1)
                : 0
          }
        }

        lancamentoEdicao.categoria = categorias != null && categorias.length > 0 ? categorias[0] : null
        lancamentoEdicao.orcamento =
          categorias != null && categorias.length > 0 ? categorias[0].orcamento : null
        lancamentoEdicao.data = data
        lancamentoEdicao.meioPagamento =
          meioPagamentos != null && meioPagamentos.length > 0 ? meioPagamentos[0] : null
        lancamentoEdicao.valorFormatado =
          lancamentoEdicao.valor != null
            ? lancamentoEdicao.valor.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })
            : null
        lancamentoEdicao.patrimonio = patrimonios != null && patrimonios.length > 0 ? patrimonios[0] : null
        lancamentoEdicao.dataVencimento = dataVencimento
        lancamentoEdicao.tipoDeParcela = lancamentoEdicao.parcelas > 1 ? lancamentoEdicao.tipoDeParcela : null

        this.setState({
          isLancamentoEdicao: true
        })

        this.setItemOnState(lancamentoEdicao, () => {
          var url =
            global.server_api_new +
            '/lancamento/familia/' +
            this.props.familyId +
            '/data-inicial/' +
            lancamentoEdicao.id

          //Se é um lançamento agrupado com mais de uma parcela
          if (lancamentoEdicao.agrupamento != null && lancamentoEdicao.parcelas > 1) {
            //Busca data inicial do lancamento
            axios.get(url).then((res) => {
              if (res.data.success && res.data.singleResult != null) {
                var temp2 = this.moment(res.data.singleResult, 'YYYY-MM-DDThh:mm:ss')
                const item = { ...this.state.item }
                item.data = temp2.toDate()
              }
            })
          }
        })

        this.setState({
          editingTransaction: true
        })
      } else {
        this.atualizarDados(true)
        this.setState({ edicao: false })
      }
    } catch (error) {
      console.log('error: ', error)
    }
  }

  limparSelecao = () => {
    this.setState({ edicao: false, editingTransaction: false })
    this.setItemOnState({
      agrupamento: null,
      ativo: null,
      categoria: null,
      custoOperacional: '',
      data: null,
      descricao: '',
      idInstituicaoFinanceira: null,
      idMovimentacao: null,
      impostoRenda: '',
      impostoRendaFormatado: '',
      iof: '',
      iofFormatado: '',
      meioPagamento: null,
      operacao: null,
      orcamento: null,
      parcelas: 1,
      patrimonio: null,
      quantidade: '',
      quantidadeFormatado: '',
      taxaAnual: '',
      taxaAnualFormatada: '',
      taxaCustomizada: '',
      taxaCustomizadaFormatada: '',
      tipoDeParcela: null,
      tipoRetirada: null,
      valor: '',
      valorFormatado: ''
    })
  }

  limparSelecaoEmail = () => {
    this.setState({
      atualNome: '',
      atualEmail: '',
      atualTelefone: '',
      atualMotivo: '',
      atualAssunto: '',
      paginaBug: '',
      familiaBug: ''
    })

    $('#arquivoEmail').val(null)
    document.getElementById('atualMensagem').value = ''
  }

  selecaoTipoLancamento = () => {
    var lancamentoEdicao = localStorage.getItem('lancamentoEdicao')

    if (lancamentoEdicao != null) {
      lancamentoEdicao = JSON.parse(lancamentoEdicao)
      localStorage.removeItem('lancamentoEdicao')

      if (lancamentoEdicao && lancamentoEdicao.tipoOrcamento === 1) {
        document.getElementById('lancamento-despesa').click()
      } else if (lancamentoEdicao && lancamentoEdicao.tipoOrcamento === 2) {
        document.getElementById('lancamento-investimento').click()
      } else if (lancamentoEdicao && lancamentoEdicao.tipoOrcamento === 3) {
        document.getElementById('lancamento-divida').click()
      } else {
        document.getElementById('lancamento-receita').click()
      }
    }
  }

  ModalHelp = () => {
    const { helpModal } = this.state
    if (![0, 1, 2].includes(helpModal.userType)) return <></>
    return <HelpModal {...this.state.helpModal} />
  }

  handleChangeOrcamento = (selectedOption) => {
    const lancamentoOrcamento = selectedOption.value
    const item = { ...this.state.item }
    item.orcamento = lancamentoOrcamento
    this.setState({ item })

    var categorias = []

    if (this.state.categorias != null && lancamentoOrcamento != null) {
      this.state.categorias.forEach((c) => {
        if (
          c.orcamento &&
          c.orcamento.id === lancamentoOrcamento.id &&
          c.suspenso === 0 &&
          c.orcamento.suspenso === 0
        ) {
          categorias.push({
            value: c,
            label: c.nome
          })
        }
      })
    }

    if (categorias != null && categorias.length === 0) {
      categorias.push({
        label: 'Nenhuma categoria cadastrada e/ou ativa neste orçamento',
        value: null
      })
    }

    //Sem selecao de orcamento
    if (lancamentoOrcamento == null) {
      this.setState({
        categoriasDeReceitasCombo: [
          {
            label: 'Selecione um orçamento...',
            value: null
          }
        ],
        categoriasDeDespesasCombo: [
          {
            label: 'Selecione um orçamento...',
            value: null
          }
        ]
      })
    }
    //Receita
    else if (lancamentoOrcamento.tipoOrcamento === 0) {
      this.setState({
        categoriasDeReceitasCombo: categorias,
        categoriasDeDespesasCombo: [
          {
            label: 'Selecione um orçamento...',
            value: null
          }
        ]
      })
    }
    //Despesa
    else if (lancamentoOrcamento.tipoOrcamento === 1) {
      this.setState({
        categoriasDeReceitasCombo: [
          {
            label: 'Selecione um orçamento...',
            value: null
          }
        ],
        categoriasDeDespesasCombo: categorias
      })
    }
  }

  getBase64 = (file, callback) => {
    var reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      callback(reader.result)
    }
  }

  handleChangePatrimonio = (patrimonio) => {
    const { categorias, patrimonioAtivos, item, familiaId } = this.state
    // const patrimonio = selectedOption.value
    let categoria = null
    let patrimonioAtivosCombo = []
    let patrimonioAtivoId = null

    if (patrimonio) {
      const nomeCategoria = patrimonio.descricao

      categoria = categorias.find(
        ({ nome, orcamento }) =>
          nomeCategoria.toLowerCase() === nome.toLowerCase() &&
          orcamento != null &&
          orcamento.tipoOrcamento === 2
      )

      if (!categoria) {
        const msg = `Categoria não encontrada ${nomeCategoria}`
        console.error(msg)
        const error = new Error(msg)
        // TODO: Decide if we should throw error
        noticeError(error, { familiaId, selectedOption: patrimonio })
      }

      patrimonioAtivosCombo = patrimonioAtivos[patrimonio.id]
      patrimonioAtivoId = patrimonioAtivosCombo?.[0]?.value ?? null
    }
    const updatedItem = {
      ...item,
      quantidade: 0,
      valor: 0,
      patrimonio,
      categoria,
      patrimonioAtivoId
    }

    this.setState({ patrimonioAtivosCombo, item: updatedItem })
  }

  handleChangeCategoria = ({ value }) => {
    const item = {
      ...this.state.item,
      categoria: value
    }
    this.setState({ item })
  }

  handleResgateTotal = () => {
    var idPatrimonioSelecionado

    if (this.state.item && this.state.item.patrimonio) {
      //validar se não tiver
      idPatrimonioSelecionado = this.state.item.patrimonio.id
    } else {
      return null
    }
    var valuePatrimonio = this.state.patrimonioAtivos[idPatrimonioSelecionado]
      ? this.state.patrimonioAtivos[idPatrimonioSelecionado][0]['value']
      : null

    var patrimonioSelecionado = this.state.patrimonioAtivosDados[valuePatrimonio]

    if (patrimonioSelecionado) {
      this.setState({
        item: {
          ...this.state.item,
          quantidade: patrimonioSelecionado[0],
          valor: patrimonioSelecionado[1] / patrimonioSelecionado[0],
          valorFormatado: this.state.patrimonioAtivosDados[
            this.state.item.patrimonioAtivoId
          ][1].toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })
        }
      })
    }
  }

  NewTransaction = () => <ModalNewTransaction parentThis={this} />

  preparaEnviarProblema = () => {
    const { familiaBug, atualAssunto, paginaBug } = this.state

    if (!document.getElementById('atualMensagem').value || !atualAssunto) {
      this.setState({ alertEnvioForm: true })
      return
    }

    if (atualAssunto === 'Bug' && (paginaBug == undefined || familiaBug == undefined)) {
      this.setState({ alertEnvioForm: true })
      return
    }

    if (!familiaBug) {
      this.setState({ alertEnvioForm: true })
      return
    }

    if (this.fileInput?.current?.files?.length > 0) {
      this.getBase64(this.fileInput.current.files[0], (result) => {
        this.enviarProblema(this.fileInput.current.files[0].name, result.split(',')[1])
      })
    } else {
      this.enviarProblema(null, null)
    }
  }

  enviarProblema = (nome, anexo) => {
    //var url = global.server_api + '/duvida';
    var url = global.server_api_new + '/planejador/duvida'
    var currentScroll = [
      document.documentElement.scrollLeft || document.body.scrollLeft,
      document.documentElement.scrollTop || document.body.scrollTop
    ]

    global.spinnerShow($)

    var envio = {
      Nome: this.state.atualNome,
      Email: this.state.atualEmail,
      Telefone: this.state.atualTelefone,
      Assunto: this.state.atualAssunto,
      PaginaBug: this.state.paginaBug,
      FamiliaBug: this.state.familiaBug,
      Mensagem: document.getElementById('atualMensagem').value,
      ArquivoBase64: anexo,
      Filename: nome
    }

    axios.post(url, envio).then((res) => {
      global.spinnerHide($, currentScroll)

      if (res.data.success === true) {
        this.limparSelecaoEmail()
        this.modalReportar.hide()
      } else {
        console.log('error!!!', res.data.exception)

        const options = {
          title: 'Erro ao enviar pedido de ajuda',
          message: res.data.exception.Message,
          buttons: [
            {
              label: 'Ok'
            }
          ]
        }

        confirmAlert(options)
      }
    })
  }

  ReportarModal = () => {
    const inputStyle = {
      paddingLeft: 25,
      height: 38
    }

    return (
      <SkyLight
        ref={(ref) => (this.modalReportar = ref)}
        transitionDuration={0}
        dialogStyles={{ borderRadius: '1rem', padding: '2rem' }}
        title={
          <>
            <div className="pop-up-title">
              <i className="ni ni-check-bold"></i> <h2>Reportar problema</h2>
            </div>
          </>
        }
      >
        <Row>
          <Col lg="6" xl="6">
            <div>
              <label>Nome *</label>
              <input type="text" id="nome" style={inputStyle} defaultValue={this.state.atualNome} disabled />
            </div>
          </Col>
          <Col lg="6" xl="6">
            <div>
              <label>Email *</label>
              <input
                type="text"
                id="email"
                style={inputStyle}
                defaultValue={this.state.atualEmail}
                disabled
              />
            </div>
          </Col>
          <Col lg="6" xl="6">
            <div>
              <label>Telefone *</label>
              <input
                type="text"
                id="telefone"
                style={inputStyle}
                defaultValue={this.state.atualTelefone}
                disabled
              />
            </div>
          </Col>
          <Col lg="6" xl="6">
            <div>
              <label>Motivo *</label>
              <Select
                options={reportar}
                className="select-component"
                placeholder="Selecione..."
                onChange={(e) => {
                  this.setState({
                    atualAssunto: e.value,
                    alertEnvioForm: false
                  })
                }}
              />
            </div>
          </Col>

          {this.state.atualAssunto === 'Bug' && (
            <>
              <Col lg="6" xl="6">
                <div>
                  <label>Página do bug *</label>
                  <Select
                    options={paginaBug}
                    className="select-component"
                    placeholder="Selecione..."
                    onChange={(e) => {
                      this.setState({
                        paginaBug: e.value,
                        alertEnvioForm: false
                      })
                    }}
                  />
                </div>
              </Col>
              <Col lg="6" xl="6">
                <div>
                  <label>Família/Cliente *</label>
                  <input
                    type="text"
                    id="nome"
                    style={inputStyle}
                    onChange={(e) => {
                      this.setState({
                        familiaBug: e.target.value,
                        alertEnvioForm: false
                      })
                    }}
                  />
                </div>
              </Col>
            </>
          )}

          <Col lg="6" xl="6">
            <div>
              <label>Arquivo</label>
              <input id="arquivoEmail" type="file" ref={this.fileInput} />
              <p style={{ fontSize: 13 }}>
                Se desejar, anexe um print da tela para entenderemos melhor o problema encontrado na
                plataforma
              </p>
            </div>
          </Col>
          <Col lg="6" xl="6">
            <div>
              <label>Mensagem *</label>
              <textarea
                id="atualMensagem"
                style={{ padding: '5px 15px', minHeight: 110 }}
                placeholder={
                  this.state.atualAssunto === 'Bug'
                    ? 'Descreva o problema encontrado e, se for o caso, ' +
                      'a operação que estava tentando fazer ao encontrar o erro e ' +
                      'qual era o comportamento esperado'
                    : 'Descreva o problema encontrado'
                }
                onChange={() => this.setState({ alertEnvioForm: false })}
              />
            </div>
          </Col>
          <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <button
              id="salvar-lancamento"
              className="featured-button"
              onClick={() => {
                this.preparaEnviarProblema()
              }}
              style={{
                backgroundImage: 'url(' + require('../../../assets/img/theme/botao-destaque.png') + ')'
              }}
            >
              Enviar
            </button>
          </div>
          <span
            style={{
              fontSize: 11,
              color: 'red',
              display: this.state.alertEnvioForm ? 'block' : 'none'
            }}
          >
            Por favor, preencha todos os campos obrigatórios antes de enviar
          </span>
        </Row>
      </SkyLight>
    )
  }

  MeuVistaAiModal = () => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.text = `
      window.__EESEL_CUSTOM_ERROR =
        "Desculpe, não consigo responder a essa pergunta. Tente reformular sua pergunta ou entre em contato com o suporte em: https://meuvista.atlassian.net/servicedesk/customer/portals";
      window.__EESEL_WELCOME_MSG = "👋 Oi! Como podemos ajudar?";
      window.__EESEL_BOT_NAME = "Vista AI";
      window.__EESEL_NAMESPACE = "cc4b28d7-b347-434a-bedf-735924ed7997";
      var e = document.createElement("script");
      e.src = "https://cdn.eesel.ai/ChatWidget.d0cf70d.js";
      e.onload = function () {
        ChatWidget.default({
          EESEL_LOGO_URL: "https://vista-prod-themes.s3.sa-east-1.amazonaws.com/logos/meu-vista.png",
          EESEL_COLOR_PALETTE: { primary: "#4F46E5" },
          EESEL_DEFAULT_OPEN: false,
          EESEL_SUGGEST_QUESTION: true,
        });
      };
      document.body.appendChild(e);
    `

    document.body.appendChild(script)
    this.scriptMeuVistaAI = script
  }

  RemoverMeuVistaAiModal = () => {
    if (this.scriptMeuVistaAI) {
      document.body.removeChild(this.scriptMeuVistaAI)
      this.scriptMeuVistaAI = null
      window.location.reload()
    }
  }

  render() {
    const { rightBarTopRoutes, familiaId, tipoUsuario } = this.props || {}

    const HelpTooltip = withStyles((theme) => ({
      tooltip: {
        backgroundColor: '#0FA4C3',
        color: 'white',
        fontSize: 12,
        fontWeight: 'bold',
        fontFamily: 'Open Sans'
      }
    }))(Tooltip)

    const ProblemTooltip = withStyles((theme) => ({
      tooltip: {
        backgroundColor: '#ec97a8',
        color: 'white',
        fontSize: 12,
        fontWeight: 'bold',
        fontFamily: 'Open Sans'
      }
    }))(Tooltip)

    const setModalIsOpen = (open = false) => {
      if (open === false) {
        this.setState({
          helpModal: {}
        })
        return
      }
      this.setState({
        helpModal: { ...this.state.helpModal }
      })
    }

    const handleLoggout = (event) => {
      this.props.resetJwt()
    }

    const hasFamily = familiaId !== null
    const isClient = tipoUsuario === 2
    const isPlanner = tipoUsuario === 1
    const isSupport = tipoUsuario === 0

    return (
      <>
        <S.Navigation role="navigation" id="navbar-main">
          <S.Group className="divider">
            {!isClient && hasFamily && (
              <li
              // style={tipoUsuario !== 2 && familiaId == null ? {} : { display: 'none' }}
              >
                <NavLink className="nav-link-icon" to="/admin/clientes" tag={Link}>
                  <span className="nav-link-inner--text">Clientes</span>
                </NavLink>
                {/* <div className="navigation-pipe">&nbsp;</div> */}
              </li>
            )}

            {/* {isPlanner && hasFamily && (
              <li
              >
                <a
                  className="nav-link-icon"
                  href="https://materiais.meuvista.com/tutoriais"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <span className="nav-link-inner--text">Tutoriais</span>
                </a>
              </li>
            )} */}

            {!isClient && hasFamily && (
              <li
              // style={tipoUsuario !== 2 && familiaId == null ? {} : { display: 'none' }}
              >
                <NavLink className="nav-link-icon" to="/index" tag={Link}>
                  <span className="nav-link-inner--text">Vista Planejador</span>
                </NavLink>
              </li>
            )}

            {isClient && hasFamily && (
              <li
              // style={tipoUsuario === 2 || familiaId != null ? {} : { display: 'none' }}
              >
                <NavLink to="/admin/patrimonio" tag={Link}>
                  <span className="nav-link-inner--text">Patrimônio</span>
                </NavLink>
                {/* <div className="navigation-pipe">&nbsp;</div> */}
              </li>
            )}

            {isClient && hasFamily && (
              <li>
                <NavLink className="nav-link-icon" to="/admin/gestao-planos-sonhos" tag={Link}>
                  <span className="nav-link-inner--text">Gestão de Planos</span>
                </NavLink>
              </li>
            )}

            {isClient && hasFamily && (
              <li>
                <NavLink to="/admin/orcamento" tag={Link}>
                  <span className="nav-link-inner--text">Gestão de Orçamento</span>
                </NavLink>
              </li>
            )}

            <ButtonNewEntry />
          </S.Group>

          <S.Group>
            <li>{this.props.familyId && <ButtonNotesModal />}</li>

            {isPlanner && (
              <li>
                <ProblemTooltip title="Criar Ticket" placement="right">
                  <S.ButtonReportarProblema
                    href="https://meuvista.atlassian.net/servicedesk/customer/portals"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Suporte
                  </S.ButtonReportarProblema>
                  {/* <NavLink
                    id="abrir-reportar-problema"
                    className="nav-link-icon adaptarNavLinkPink"
                    style={{
                      height: 40,
                      borderRadius: '24px',
                      background: '#ec97a8',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                    href="https://meuvista.atlassian.net/servicedesk/customer/portals"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="aLink">
                      <span
                        style={{
                          fontSize: '16px',
                          fontWeight: 'bolder',
                          color: 'white'
                        }}
                      >
                        Suporte
                      </span>
                    </span>
                  </NavLink> */}
                </ProblemTooltip>
              </li>
            )}

            <li>
              <HelpModalButton
                bgColor={this.context.theme.colors.primary}
                userType={parseInt(localStorage.getItem('tipo-usuario'))}
                onClick={() => {
                  const userType = parseInt(localStorage.getItem('tipo-usuario'))

                  this.setState({
                    helpModal: {
                      userType,
                      setIsOpen: setModalIsOpen,
                      open: true
                    }
                  })
                }}
              />
              {/* <HelpTooltip title={`Tutoriais${2}`} placement="right">
                <NavLink
                  id="abrir-ajuda"
                  className="nav-link-icon adaptarNavLinkBlue"
                  style={{
                    background: this.context.theme.colors.primary,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                  onClick={() => {
                    const userType = parseInt(localStorage.getItem('tipo-usuario'))

                    this.setState({
                      helpModal: {
                        userType,
                        setIsOpen: setModalIsOpen,
                        open: true
                      }
                    })
                  }}
                >
                  <span
                    style={{
                      fontWeight: 'bolder',
                      color: 'white'
                    }}
                  >
                    <i class="fas fa-play-circle" />
                  </span>
                </NavLink>
              </HelpTooltip> */}
            </li>

            <li className="no-padding">
              <S.LogoutButton onClick={handleLoggout} navbar>
                {/* {this.createLinks(rightBarTopRoutes)} */}
                Sair
                <i className="fas fa-sign-out-alt" />
              </S.LogoutButton>
            </li>
          </S.Group>
        </S.Navigation>

        {this.NewTransaction()}
        {this.ReportarModal()}
        {this.ModalHelp()}
      </>
    )
  }
}

export default withManualInvestments(AdminNavbar)
