import { PlannerByCompanyComplete } from '../planner'
import { getWithToken } from '../../../webClient'
import { noticeError } from '../../../services/Monitoring'

export type Params = {
  companyId: number
  search?: string
  desc: boolean
  orderBy?: string
  pageIndex: number
  pageSize: number
  filterDate: Date
}

export type Response = {
  planners: PlannerByCompanyComplete[]
  quantityOfFamilies: number
  totalInvoice: number
}

export const getPlannersByCompanyId = async ({
  companyId,
  desc = true,
  orderBy,
  pageIndex = 0,
  pageSize = 10,
  search,
  filterDate
}: Params) => {
  const params = { desc, orderBy, pageIndex, pageSize, nameOrEmail: search, filterDate }
  const url = `/empresa/${companyId}/planners`

  const { data: planners, status } = await getWithToken<Response>(url, { params })

  if (status !== 200) {
    const msg = `Error getting planners by company id ${companyId}`
    const error = new Error(msg)
    console.error(error)
    noticeError(error, { companyId })
    throw new Error(msg)
  }

  return planners
}
